import axios from 'axios';
import { API_COBRANZAS, API_NAPBUS } from 'config/api.config';
import { SESSION_USER } from 'config/session';
import StorageService from 'config/StorageService';

const SESSION_DATA = StorageService.get(SESSION_USER);

export const USER = SESSION_DATA?.user;
export const TOKEN = SESSION_DATA?.token;
export const COMPANY = SESSION_DATA?.company;

export const NapBusesAxios = axios.create({ baseURL: API_NAPBUS, headers: { Authorization: TOKEN } });
export const CobranzasAxios = axios.create({ baseURL: API_COBRANZAS, headers: { Authorization: TOKEN } });
